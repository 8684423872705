:root {
  --primary-blue: #2d438e;
  --primary-red: #d31714;
}

.about-page {
  font-family: 'Arial', sans-serif;
}

.breadcrumb-style1 {
  position: relative;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.breadcrumb-style1-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.7;
}

.breadcrumb-style1 .inner-content {
  position: relative;
  z-index: 1;
}

.breadcrumb-style1 .inner-content {
  position: relative;
}

.breadcrumb-style1 h2 {
  font-size: 48px;
  margin-bottom: 10px;
}

.breadcrumb-menu ul {
  list-style: none;
  padding: 0;
}

.breadcrumb-menu ul li {
  display: inline;
  margin: 0 5px;
}

.about-style1 {
  padding: 80px 0;
}

.about-style1__img-box {
  position: relative;
  margin-bottom: 30px;
}

.about-style1__img-box img {
  width: 100%;
  border-radius: 8px;
}

.experience-box {
  position: absolute;
  bottom: -30px;
  left: 20px;
  background-color: var(--primary-blue);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.experience-box h2 {
  font-size: 36px;
  margin: 0;
}

.about-style1__content-box h1 {
  color: var(--primary-blue);
  margin-bottom: 20px;
}

.about-style1__content-box h1 span {
  color: var(--primary-red);
}

.btn-one {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--primary-blue);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-one:hover {
  background-color: var(--primary-red);
}

.vision-mission, .why-choose-us, .our-services, .our-clients {
  padding: 60px 0;
  background-color: #f8f8f8;
}

.vision-mission h2, .why-choose-us h2, .our-services h2, .our-clients h2 {
  color: var(--primary-blue);
  margin-bottom: 30px;
  text-align: center;
}

.why-choose-us {
  padding: 60px 0;
  background-color: #f8f8f8;
}

.why-choose-us h2 {
  color: var(--primary-blue);
  margin-bottom: 30px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
}

.reason-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reason-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.reason-card h3 {
  color: var(--primary-red);
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: bold;
}

.reason-card p {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.5;
  flex-grow: 1;
}
.service-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.service-icon {
  width: 60px;
  height: 60px;
  background-color: var(--primary-red);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.service-icon svg {
  font-size: 30px;
  color: #fff;
}

.service-card:hover .service-icon {
  background-color: var(--primary-blue);
}

.service-card p {
  color: var(--primary-blue);
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

/* Slick slider custom styles */
.slick-dots li button:before {
  color: var(--primary-blue);
}

.slick-dots li.slick-active button:before {
  color: var(--primary-red);
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: var(--primary-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 768px) {
  .why-choose-us h2 {
    font-size: 2rem;
  }

  .reason-card {
    margin-bottom: 15px;
  }

  .reason-card h3 {
    font-size: 1.1rem;
  }

  .reason-card p {
    font-size: 0.85rem;
  }
}


.our-services ul {
  list-style-type: none;
  padding-left: 0;
}

.our-services li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.our-services li::before {
  content: "•";
  color: var(--primary-red);
  position: absolute;
  left: 0;
}

.client-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.client-label {
  background-color: var(--primary-blue);
  color: #fff;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .breadcrumb-style1 h2 {
    font-size: 36px;
  }

  .experience-box {
    position: static;
    margin-top: 20px;
  }

  .about-style1__content-box {
    text-align: center;
  }

  .vision-mission .col-md-6 {
    margin-bottom: 30px;
  }

  .client-label {
    font-size: 12px;
  }
}