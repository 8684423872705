.m {
  width: 100%;
  z-index: 999;
}

.flm {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.dx {
  display: flex;
  align-items: center;
}

.dx div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
}

.dx div:hover {
  color: #df252a;
}

.Float {
  text-align: right;
}

.Float .col-6 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  font-weight: 500;
}

.Float ion-icon {
  font-size: 20px;
  color: #df252a;
}

.vv {
  text-align: right;
}

.vv a {
  display: inline-block;
  padding: 8px 20px;
  background: #df252a;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.vv a:hover {
  background: #b71c1c;
}

@media (max-width: 768px) {
  .Float {
    margin-top: 15px;
  }
  
  .dx div {
    font-size: 14px;
  }
  
  .Float .col-6 {
    font-size: 14px;
  }
  
  .vv a {
    padding: 6px 15px;
    font-size: 14px;
  }
} 