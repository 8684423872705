/* Contact.css */
:root {
  --primary-blue: #2d438e;
  --primary-red: #d31714;
}

/* Contact Us Page Styles */

/* Breadcrumb Style */
.breadcrumb-style1 {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 30px 0; /* Reduced padding */
  z-index: 10;
}

.breadcrumb-style1-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  height: 200px; /* Set fixed height */
}

.breadcrumb-style1-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.70) 21.78%, rgba(0, 0, 0, 0.00) 78.41%);
}

.breadcrumb-style1 .inner-content {
  position: relative;
  display: block;
}

.breadcrumb-style1 .title h2 {
  color: #ffffff;
  font-size: 36px; /* Reduced font size */
  line-height: 1.2em;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px; /* Added margin */
}

.breadcrumb-menu {
  padding-top: 10px; /* Reduced padding */
}

.breadcrumb-menu ul {
  display: flex;
  align-items: center;
}

.breadcrumb-menu ul li {
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 18px;
  margin-right: 10px;
  letter-spacing: 0.09em;
}

.breadcrumb-menu ul li:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 6px;
  width: 9px;
  height: 9px;
  border: 2px solid var(--primary-blue);
  border-radius: 50%;
}

.breadcrumb-menu ul li:last-child:before {
  display: none;
}

.breadcrumb-menu ul li a {
  color: #FFFFFF;
  transition: all 200ms linear;
}

.breadcrumb-menu ul li:hover a,
.breadcrumb-menu ul li.active {
  color: var(--primary-blue);
}

/* Contact Info Style */
.contact-info-style1 {
  background-color: #ffffff;
  padding: 40px 0; /* Reduced padding */
}

.contact-info-style1__inner ul {
  display: flex;
  justify-content: space-between; /* Changed to space-between */
  flex-wrap: wrap;
  margin: 0 -15px; /* Added negative margin */
}

.contact-info-style1__inner ul li {
  display: flex;
  align-items: center;
  padding: 0 15px; /* Added padding */
  margin-bottom: 20px; /* Added margin-bottom for mobile */
  width: calc(25% - 30px); /* Set width for desktop */
}

.contact-info-style1__inner ul li:before {
  display: none; /* Removed divider */
}

.contact-info-style1__inner .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Reduced size */
  height: 50px; /* Reduced size */
  background: #f9f6f1;
  border-radius: 50%;
  margin-right: 10px; /* Reduced margin */
}

.contact-info-style1__inner .content-box p {
  margin: 0;
  font-size: 12px; /* Reduced font size */
}

.contact-info-style1__inner .content-box h4 {
  font-size: 14px; /* Reduced font size */
  margin-top: 3px; /* Reduced margin */
}

/* Contact Form Style */
.main-contact-form-area {
  padding: 60px 0;
  background: #ffffff;
}

.contact-page__contact-info .sec-title h4 {
  color: var(--primary-blue);
  font-size: 18px;
  margin-bottom: 10px;
}

.contact-page__contact-info .sec-title h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-form {
  max-width: 665px;
  margin-left: auto;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  height: 65px;
  background: #f1f1f1;
  border: none;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 8px;
}

.contact-form textarea {
  height: 150px;
  padding: 20px;
}

.contact-form .btn-one {
  background: var(--primary-blue);
  color: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-form .btn-one:hover {
  background: var(--primary-red);
}

/* Social Links Style */
.social-links ul {
  display: flex;
  margin-top: 20px;
}

.social-links ul li {
  margin-right: 10px;
}

.social-links ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f9f6ef;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.social-links ul li a:hover {
  background: var(--primary-blue);
  color: #ffffff;
}

/* Google Map Style */
.google-map1 {
  position: relative;
}

.google-map iframe {
  width: 100%;
  height: 450px;
  border: none;
}

/* Responsive Styles */
@media (max-width: 991px) {
  .contact-info-style1__inner ul li {
    width: calc(50% - 30px); /* 2 items per row on tablets */
  }
  
  .contact-form {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .contact-info-style1__inner ul li {
    width: 100%; /* Full width on mobile */
  }
  
  .breadcrumb-style1 .title h2 {
    font-size: 28px;
  }
  
  .contact-page__contact-info .sec-title h2 {
    font-size: 28px;
  }
}