@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --thm-font: 'Kumbh Sans', sans-serif;
    --thm-primary: #0D3D9F;
    --thm-base: #fe0000;
    --thm-black: #222222;
    --thm-gray: #515151;
    --thm-gray-bg: #F8F6F0;
    --thm-red: #ff0000;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container {
    max-width: 1320px;
    padding: 0px 15px;
    margin: 0 auto;
}

.main-header {
    position: relative;
    display: block;
    width: 100%;
    background: transparent;
    transition: all 500ms ease;
    z-index: 99;
}

.main-header.stricky-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.main-header__top {
    background-color: var(--thm-gray-bg);
    padding: 7px 0px;
}

.main-header__top-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-header__top-left p {
    color: var(--thm-black);
    font-size: 14px;
    line-height: 24px;
}

.main-header__top-left p a {
    color: var(--thm-base);
    font-weight: 500;
}

.header-social-link-style1 ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.header-social-link-style1 ul li {
    margin-left: 10px;
}

.header-social-link-style1 ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: transparent;
    border-radius: 50%;
    color: #AAAAAA;
    font-size: 14px;
    transition: all 200ms linear;
}

.header-social-link-style1 ul li a:hover {
    color: #ffffff;
    background-color: var(--thm-base);
}

.main-header__bottom {
    padding: 20px 0px;
}

.main-header__bottom-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-box-style1 img {
    max-height: 60px;
}

.contact-info-box-style1 ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.contact-info-box-style1 ul li {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.contact-info-box-style1 ul li .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: rgba(var(--thm-base-rgb), .10);
    border-radius: 50%;
}

.contact-info-box-style1 ul li .icon span {
    color: var(--thm-primary);
    font-size: 25px;
    line-height: 25px;
}

.contact-info-box-style1 ul li .text {
    padding-left: 20px;
}

.contact-info-box-style1 ul li .text p {
    color: #515151;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.contact-info-box-style1 ul li .text h4 {
    color: #000;
    font-size: 18px;
    line-height: 28px;
    margin: 2px 0 0;
}

.main-menu-style1 {
    background-color: var(--thm-primary);
}

.main-menu__wrapper-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-menu__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-menu__list > li {
    padding: 24px 20px;
}

.main-menu__list > li > a {
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    transition: color 0.3s ease;
}

.main-menu__list > li > a:hover,
.main-menu__list > li > a:active {
    color: var(--thm-red);
}

.header-btn-style1 .btn-one {
    display: inline-block;
    padding: 10px 35px;
    background-color: #ffffff;
    color: var(--thm-primary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
    text-decoration: none;
    transition: all 200ms linear;
}

.header-btn-style1 .btn-one:hover {
    background-color: var(--thm-base);
    color: #ffffff;
}

.mobile-nav__toggler {
    display: none;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 5px;
}

.mobile-logo {
    display: none;
}

.header-upper.hidden {
    display: none;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #faf6f6;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(156, 1, 1, 0.2);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.dropdown-menu.show {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu li {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-menu li:hover {
    background-color: #ddd;
}

.dropdown > a > i {
    margin-left: 5px;
    transition: transform 0.3s;
}

.dropdown > a > i.rotate {
    transform: rotate(180deg);
}

@media (max-width: 1199px) {
    .contact-info-box-style1 ul {
        flex-wrap: wrap;
    }

    .contact-info-box-style1 ul li {
        margin-left: 20px;
        margin-bottom: 10px;
    }
}

@media (max-width: 991px) {
    .main-header__bottom-inner {
        flex-direction: column;
    }

    .main-header__bottom-right {
        margin-top: 20px;
    }

    .main-menu__list {
        display: none;
    }

    .mobile-nav__toggler {
        display: block;
    }

    .main-menu__wrapper-inner {
        justify-content: space-between;
    }

    .main-menu-style1__left {
        flex: 1;
        display: flex;
        align-items: center;
    }

   
    .main-menu-style1__right {
        flex: 0 0 auto;
    }

    .dropdown-menu {
        position: static;
        background-color: transparent;
        box-shadow: none;
        display: none;
    }

    .dropdown-menu.show {
        display: block;
    }

    .dropdown-menu li {
        padding: 10px 20px;
    }

    .dropdown > a > i {
        float: right;
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    .main-header__top-inner {
        flex-direction: column;
    }

    .main-header__top-right {
        margin-top: 10px;
    }

    .contact-info-box-style1 {
        display: none;
    }

    .main-menu__wrapper-inner {
        flex-wrap: wrap;
    }

    .main-menu-style1__left {
        flex: 0 0 100%;
        margin-bottom: 10px;
    }

    .main-menu-style1__right {
        flex: 0 0 100%;
        text-align: center;
    }

  
    .main-menu__list {
        display: none;
    }

    .main-menu__list.mobile-menu-visible {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--thm-primary);
        padding: 10px 0;
    }

    .main-menu__list.mobile-menu-visible > li {
        padding: 10px 20px;
    }

    .main-menu__list.mobile-menu-visible .mobile-logo {
        display: block;
        margin-bottom: 20px;
        text-align: center;
    }

    .main-menu__list.mobile-menu-visible .mobile-logo img {
        max-height: 40px;
    }
}

/* Media query to show contact info on tablets and larger screens */
@media (min-width: 768px) {
    .contact-info-box-style1 {
        display: block;
    }
}