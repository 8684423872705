/*** 
=============================================
    Blog Style1 Css
=============================================
***/
.blog-style1 {
  position: relative;
  display: block;
  
  background-image: url("../../img/wallpapertd.png");
  padding: 120px 0 90px;
  z-index: 10;
}

.single-blog-style1 {
  position: relative;
  display: block;
  background: #ffffff;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  margin-bottom: 30px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.single-blog-style1:hover {
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.10);
  border: 1px solid transparent;
}

.single-blog-style1__top {
  position: relative;
  display: block;
  padding: 32px 40px 35px;
}

.single-blog-style1__top .meta-box {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.single-blog-style1__top .meta-box .meta-info {
  position: relative;
  display: block;
  overflow: hidden;
}

.single-blog-style1__top .meta-box .meta-info li {
  position: relative;
  display: block;
  float: left;
  margin-right: 20px;
}

.single-blog-style1__top .meta-box .meta-info li:last-child {
  margin-right: 0;
}

.single-blog-style1__top .meta-box .meta-info li a {
  color: #515151;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.single-blog-style1__top .meta-box .meta-info li a:hover {
  color: var(--thm-base);
}

.single-blog-style1__top h2 {
  font-size: 24px;
  line-height: 38px;
  text-transform: capitalize;
}

.single-blog-style1__top h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.single-blog-style1__top h2 a:hover {
  color: var(--thm-base);
}



.single-blog-style1 .img-holder {
  position: relative;
  display: block;
}

.single-blog-style1 .img-holder .inner {
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
}

.single-blog-style1 .img-holder .inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0.5s ease;
  z-index: 2;
}

.single-blog-style1:hover .img-holder .inner::before {
  opacity: 0.50;
}

.single-blog-style1 .img-holder .inner img {
  width: 100%;
  transform: scale(1.0);
}

.single-blog-style1:hover .img-holder .inner img {
  transform: scale(1.05) rotate(0deg);
}



.single-blog-style1__bottom {
  position: relative;
  display: block;
  padding: 30px 40px 45px;
}

.single-blog-style1__bottom p {
  margin: 0;
}

.single-blog-style1__bottom .btn-box {
  position: relative;
  display: block;
  margin-top: 22px;
}

.single-blog-style1__bottom .btn-box a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 50px;
  border-bottom: 3px solid #0099ff;
}

.single-blog-style1__bottom .btn-box a span:before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 20px;
  line-height: 20px;
  padding-left: 5px;
  transform: rotate(90deg);
  font-weight: 700;
  transition: all 400ms linear;
  transition-delay: 0.1s;
}

.single-blog-style1:hover .single-blog-style1__bottom .btn-box a span:before {
  transform: rotate(0deg);
}


/*** 
=============================================
  Blog Page One Css
=============================================
***/
.blog-page-one {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 120px 0 120px;
  z-index: 10;
}

.blog-page-one .styled-pagination {
  justify-content: center;
}


/*** 
=============================================
  Blog Page Two Css
=============================================
***/
.blog-page-two {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 120px 0px 120px;
  z-index: 1;
}

.blog-page-two__content {
  position: relative;
  display: block;
  margin-right: 30px;
}

.blog-page-two__single {
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 60px;
  transition: box-shadow 0.5s ease;
  transition: all 0.5s ease;
  z-index: 1;
}

.blog-page-two__single:hover {
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.10);
}

.blog-page-two__single::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid #dedede;
  border-radius: 8px;
  content: "";
  z-index: -1;
  transition: all 400ms linear;
  transition-delay: 0.1s;
}

.blog-page-two__single:hover::before {
  border: 1px solid #ffffff;
}

.blog-page-two__single-img {
  position: relative;
  display: block;
  background-color: #000000;
  border-radius: 8px;
  overflow: hidden;
}

.blog-page-two__single-img img {
  width: 100%;
  transition: background-color 0.5s ease;
  transition: all 0.5s ease;
}

.blog-page-two__single:hover .blog-page-two__single-img img {
  transform: scale(1.05) rotate(0deg);
  opacity: 0.3;
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.blog-page-two__single-content {
  position: relative;
  display: block;
  padding: 50px 50px 50px;
  padding-right: 90px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.blog-page-two__single-content .meta-info {
  position: relative;
  display: block;
  margin-bottom: 31px;
}

.blog-page-two__single-content .meta-info li {
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
}

.blog-page-two__single-content .meta-info li:last-child {
  margin-right: 0px;
}

.blog-page-two__single-content .meta-info li a {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--thm-gray);
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  background: #f8f6f0;
  border-radius: 5px;
  padding: 9px 20px 9px;
}

.blog-page-two__single-content .meta-info li a span {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 17px;
  line-height: 17px;
  padding-right: 9px;
  top: -2px;
}



.blog-page-two__single-content h2 {
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
  margin-bottom: 22px;
}

.blog-page-two__single-content h2 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.blog-page-two__single-content h2 a:hover {
  color: var(--thm-base);
}

.blog-page-two__single-content p {
  margin: 0;
}

.blog-page-two__single-content .btn-box {
  position: relative;
  display: block;
  margin-top: 35px;
}

.blog-page-two__single-content .btn-box .btn-one {
  color: #0099ff;
}

.blog-page-two__single-content .btn-box .btn-one:hover {
  color: #ffffff;
}

.blog-page-two__single-content .btn-box a:after {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.blog-page-two__single-content .btn-box a::before {
  background: #0099ff;
}

.blog-page-two__single-content .btn-box .btn-one i::before {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 13px;
  font-weight: 700;
  top: -1px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  z-index: 5;
}

.blog-page-two__single-content .btn-box .btn-one:hover i::before {
  color: #ffffff;
}







/*** Thm Sidebar Box Css****/
.thm-sidebar-box {
  position: relative;
  display: block;
}


/***
.sidebar-categories-box {
  position: relative;
  display: block;
  padding-top: 16px;
}

.sidebar-categories-box li {
  position: relative;
  display: block;
  border-bottom: 1px solid #d5cac3;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.sidebar-categories-box li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sidebar-categories-box li:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 0;
  height: 1px;
  background-color: var(--thm-base);
  transition: all 600ms ease 100ms;
  z-index: 1;
}

.sidebar-categories-box li:last-child::after {
  display: none;
}

.sidebar-categories-box li:hover:after {
  width: 100%;
}

.sidebar-categories-box li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--thm-gray);
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  font-family: var(--thm-font);
  letter-spacing: 0.0em;
  transition: all 300ms linear;
  transition-delay: 0.1s;
  z-index: 1;
}

.sidebar-categories-box li a span {
  color: #817e7c;
  font-size: 10px;
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.sidebar-categories-box li:hover a span {
  color: var(--thm-base);
}

.sidebar-categories-box li:hover a {
  color: var(--thm-base);
  padding-left: 5px;
}
***/


.sidebar-blog-post-box {
  position: relative;
  display: block;
}

.sidebar-blog-post {
  position: relative;
  display: block;
}

.sidebar-blog-post ul {
  position: relative;
  display: block;
}

.sidebar-blog-post ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.sidebar-blog-post ul li:last-child {
  border-bottom: none;
  padding: 0;
  margin-bottom: 0;
}

.sidebar-blog-post ul li .inner {
  position: relative;
  display: block;
  padding-left: 80px;
}

.sidebar-blog-post ul li .img-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 8px;
}

.sidebar-blog-post ul li .img-box img {
  width: 100%;
}

.sidebar-blog-post ul li .img-box .overlay-content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(var(--thm-base-rgb), .80);
  border-radius: 0%;
  opacity: 0;
  transform: perspective(0px) scale(0);
  transform-origin: center;
  transition: all 0.5s ease-in-out 0s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.sidebar-blog-post ul li:hover .img-box .overlay-content {
  opacity: 1;
  transform: perspective(400px) scale(1.0);
  transition: all 0.3s ease-in-out 0.3s;
}

.sidebar-blog-post ul li .img-box .overlay-content a {
  color: #ffffff;
  font-size: 14px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.sidebar-blog-post ul li .title-box {
  position: relative;
  display: block;
  padding-left: 20px;
  min-height: 80px;
  margin: 0;
}

.sidebar-blog-post ul li .title-box h4 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: var(--thm-font);
  margin: 9px 0 0px;
}

.sidebar-blog-post ul li .title-box h4 a {
  color: var(--thm-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.sidebar-blog-post ul li .title-box h4 a:hover {
  color: var(--thm-base);
}

.sidebar-blog-post ul li .title-box .meta-box {
  position: relative;
  display: flex;
  align-items: center;
}

.sidebar-blog-post ul li .title-box .meta-box p {
  color: #515151;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.sidebar-blog-post ul li .title-box .meta-box p+p {
  margin-left: 20px;
}

.sidebar-blog-post ul li .title-box .meta-box p a {
  color: #515151;
}


.popular-tag-box {
  position: relative;
  display: block;
}

.single-sidebar-box .popular-tag {
  position: relative;
  display: block;
  overflow: hidden;
  margin-left: -5px;
  margin-right: -5px;
}

.single-sidebar-box .popular-tag li {
  position: relative;
  display: block;
  float: left;
  margin: 0 5px 10px;
}

.single-sidebar-box .popular-tag li a {
  position: relative;
  display: block;
  padding: 9px 19px 9px;
  background: #ffffff;
  color: var(--thm-gray);
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 8px;
  transition: all 500ms ease;
  font-family: var(--thm-font);
}

.single-sidebar-box .popular-tag li:hover a {
  color: #ffffff;
  background-color: var(--thm-base);
}





/***
=============================================
  Blog Details Area Css
=============================================
***/
.blog-details-area {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 120px 0 120px;
  z-index: 10;
}

.blog-details-box {
  position: relative;
  display: block;
  margin-right: 30px;
}

.blog-details-box .blog-page-two__single:hover {
  box-shadow: none;
}

.blog-details-box .blog-page-two__single:hover::before {
  border: 1px solid #dedede;
}

.blog-details-box .blog-page-two__single-content p+p {
  margin: 21px 0 0;
}

.blog-details-box .blog-page-two__single-content {
  padding: 50px 50px 60px;
}



.blog-details-text-1 {
  position: relative;
  display: block;
  margin-top: 40px;
}

.blog-details-text-1 .text-box {
  position: relative;
  display: block;
}

.blog-details-text-1 .text-box h3 {
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
  margin-bottom: 17px;
}

.blog-details-text-1 .text-box p {
  margin-bottom: 0;
}

.blog-details-text-1 .text-box ul {
  position: relative;
  display: block;
  margin-top: 21px;
}

.blog-details-text-1 .text-box ul li {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 10px;
}

.blog-details-text-1 .text-box ul li .icon {
  position: absolute;
  top: 6px;
  left: 0;
  line-height: 0;
}

.blog-details-text-1 .text-box ul li .icon span {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 20px;
  line-height: 20px;
}

.blog-details-text-1 .text-box ul li p {
  margin: 0;
}

.blog-details-text-1 .img-box {
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 37.5px;
}

.blog-details-text-1 .img-box img {
  width: 100%;
}



.blog-details-text-2 {
  position: relative;
  display: block;
  margin-top: 38px;
}

.blog-details-text-2 h3 {
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.blog-details-text-2 p {
  margin: 0;
  margin-bottom: 35px;
}

.blog-details-text-2 .single-box {
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
}

.blog-details-text-2 .single-box img {
  width: 100%;
}



.tag-social-share-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  margin-top: 40px;
  padding-top: 42px;
}

.tag-social-share-box .tag-box {
  position: relative;
  display: block;
}

.tag-social-share-box .tag-box .tag-list {
  position: relative;
  display: block;
}

.tag-social-share-box .tag-box .tag-list li {
  position: relative;
  display: block;
  margin-right: 10px;
  float: left;
  line-height: 40px;
}

.tag-social-share-box .tag-box .tag-list li:last-child {
  margin-right: 0;
}

.tag-social-share-box .tag-box .tag-list li a {
  position: relative;
  display: block;
  color: var(--thm-gray);
  font-size: 12px;
  line-height: 40px;
  font-weight: 500;
  text-transform: uppercase;
  background: #f9f6f1;
  border-radius: 8px;
  padding: 0px 20px 0px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.tag-social-share-box .tag-box .tag-list li a:hover {
  color: #ffffff;
  background: var(--thm-base);
}

.post-social-share {
  position: relative;
  display: block;
}

.post-social-share-links {
  position: relative;
  display: block;
}

.post-social-share-links .share-btn {
  position: relative;
  display: block;
}

.post-social-share-links .share-btn a {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  background: var(--thm-base);
  border-radius: 8px;
  padding: 9px 15px 7px;
}

.post-social-share-links .share-btn a span {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  line-height: 15px;
  top: 1px;
  padding-left: 9px;
}

.post-social-share-links ul {
  position: absolute;
  top: -180px;
  right: 5px;
  line-height: 0;
  transform: scaleY(0);
  transform-origin: center;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  transform-origin: bottom center;
}

.post-social-share-links:hover ul {
  transform: scaleY(1.0);
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -ms-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
}

.post-social-share-links ul li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.post-social-share-links ul li:last-child {
  margin-bottom: 0px;
}

.post-social-share-links ul li a {
  position: relative;
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: var(--thm-base);
  border-radius: 5px;
  z-index: 1;
}

.post-social-share-links ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--thm-primary);
  border-radius: 5px;
  transform: scale(0.5);
  opacity: 0;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  z-index: -1;
}

.post-social-share-links ul li a:hover::before {
  transform: scale(1.0);
  opacity: 1;
}

.post-social-share-links ul li a i {
  position: relative;
  display: block;
}

.post-social-share-links ul li a i::before {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.post-social-share-links ul li a:hover i::before {
  color: #ffffff;
}




/*** comments Box Css ***/
.comment-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.comment-box .inner-title {
  position: relative;
  display: block;
  padding-bottom: 41px;
}

.comment-box .inner-title h2 {
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
}

.comment-box__inner {
  position: relative;
  display: block;
}

.comment-box .single-comment {
  position: relative;
  display: block;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 40px 40px 40px;
  margin-bottom: 40px;
}

.comment-box .single-comment-box {
  position: relative;
  display: block;
  padding-left: 140px;
  min-height: 100px;
}

.comment-box .single-comment-box .img-holder {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
}

.comment-box .single-comment-box .img-holder img {
  width: 100%;
}

.comment-box .single-comment-box .text-holder {
  position: relative;
  display: block;
  min-height: 100px;
}

.comment-box .single-comment-box .text-holder .top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.comment-box .single-comment-box .text-holder .top .left {
  position: relative;
  display: block;
}

.comment-box .single-comment-box .text-holder .top .left h3 {
  font-size: 18px;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.comment-box .single-comment-box .text-holder .top .left .date-box {
  position: relative;
  display: block;
}

.comment-box .single-comment-box .text-holder .top .left .date-box p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.comment-box .single-comment-box .text-holder .top .left .date-box p span {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 15px;
  line-height: 15px;
  padding-right: 10px;
  top: 1px;
}

.comment-box .single-comment-box .text-holder .top .right {
  position: relative;
  display: block;
}

.comment-box .single-comment-box .text-holder .top .right a {
  color: #0D3D9F;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.comment-box .single-comment-box .text-holder .top .right a:hover {
  color: var(--thm-base);
}

.comment-box .single-comment-box .text-holder .top .right a span {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
  font-size: 20px;
  line-height: 20px;
  padding-right: 9px;
  top: 3px;
}

.comment-box .single-comment-box .text-holder .text {
  position: relative;
  display: block;
  margin-top: 18px;
}

.comment-box .single-comment-box .text-holder .text p {
  margin: 0;
}




.reply-form-box {
  position: relative;
  display: block;
  padding-top: 41px;
}

.reply-form-box .title {
  position: relative;
  display: block;
  padding-bottom: 41px;
}

.reply-form-box .title h2 {
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
}

.reply-form-box #review-form {
  position: relative;
  display: block;
}


.reply-form-box form .input-box {
  position: relative;
  display: block;
}

.reply-form-box form input[type="text"],
.reply-form-box form input[type="email"],
.reply-form-box form textarea {
  position: relative;
  display: block;
  background: #F1F1F1;
  width: 100%;
  height: 65px;
  border: 1px solid #F1F1F1;
  color: #515151;
  font-size: 17px;
  padding: 0 30px;
  margin-bottom: 20px;
  border-radius: 8px;
  transition: all 500ms ease;
}

.reply-form-box form textarea {
  height: 150px;
  padding: 10px 30px;
  margin-bottom: 30px;
}

.reply-form-box form button {}

.reply-form-box form input[type="text"]:focus {
  border-color: #11161e;
}

.reply-form-box form input[type="email"]:focus {
  border-color: #11161e;
}

.reply-form-box form textarea:focus {
  border-color: #11161e;
}










/*** 
=============================================
  End Css
=============================================
***/
/* General Styles */
.blog-post-full {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 15px auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post-full h1 {
  font-size: 36px;
  color: #2d438e;
  margin-bottom: 20px;
}

.blog-post-full img {
  max-width: 100%;
  height: auto;
  margin: 15px 0;
}

.blog-post-full p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.back-to-blog {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 15px;
  color: #fff;
  background-color: #d32f2f;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.back-to-blog:hover {
  background-color: #b71c1c;
}

/* Sidebar Styles */
.thm-sidebar-box {
  margin-top: 30px;
}

.single-sidebar-box {
  margin-bottom: 30px;
}

.title-box h2 {
  font-size: 24px;
  color: #2d438e;
  margin-bottom: 15px;
}

.border-box {
  width: 40px;
  height: 3px;
  background-color: #0099ff;
  margin-bottom: 20px;
}

.sidebar-popular-services-list li {
  margin-bottom: 10px;
}

.sidebar-popular-services-list li a {
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sidebar-popular-services-list li.current a,
.sidebar-popular-services-list li a:hover {
  background-color: #0099ff;
  color: #fff;
}

.sidebar-images-box .img-holder {
  margin-bottom: 20px;
}

.sidebar-images-box .img-holder img {
  width: 100%;
  border-radius: 5px;
}

.popular-tag-box ul {
  list-style: none;
  padding: 0;
}

.popular-tag-box ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.popular-tag-box ul li a {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f9f9f9;
  color: #333;
  border-radius: 3px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.popular-tag-box ul li a:hover {
  background-color: #0099ff;
  color: #fff;
}
