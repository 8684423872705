/* General Styles */
#blog {
  padding: 20px 0;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Breadcrumb Styles */
.breadcrumb-style1 {
  position: relative;
  padding: 100px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumb-style1 .inner-content {
  position: relative;
  text-align: center;
  color: #ffffff;
}

.breadcrumb-style1 .title h2 {
  font-size: 36px;
  color: #ffffff;
}

.breadcrumb-style1 .breadcrumb-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumb-style1 .breadcrumb-menu ul li {
  display: inline-block;
  margin-right: 10px;
  color: #ffffff;
}

.breadcrumb-style1 .breadcrumb-menu ul li a {
  color: #ffffff;
  text-decoration: none;
}

.breadcrumb-style1 .breadcrumb-menu ul li.active {
  font-weight: bold;
}

/* Blog Page Styles */
.blog-page-one {
  padding: 120px 0 90px;
}

.single-blog-style1 {
  position: relative;
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.single-blog-style1:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.single-blog-style1__top .meta-box {
  padding: 15px;
}

.single-blog-style1__top .meta-box .meta-info {
  list-style: none;
  padding: 0;
  margin: 0;
}

.single-blog-style1__top .meta-box .meta-info li {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  color: #999;
}

.single-blog-style1__top h2 {
  font-size: 24px;
  line-height: 34px;
  margin: 15px 0;
  padding: 0 15px;
}

.single-blog-style1__top h2 a {
  color: #2d438e;
  text-decoration: none;
  transition: color 0.3s ease;
}

.single-blog-style1__top h2 a:hover {
  color: #d32f2f;
}

.img-holder {
  position: relative;
  height: 200px; /* Fixed height for uniform images */
  overflow: hidden;
  border-radius: 8px;
}

.img-holder .inner {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container uniformly */
  transition: transform 0.5s ease;
}

.single-blog-style1:hover .img-holder img {
  transform: scale(1.05);
}

.single-blog-style1__bottom {
  padding: 20px 15px;
}

.single-blog-style1__bottom p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.btn-box {
  margin-top: 10px;
}

.btn-box a {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #d32f2f;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.btn-box a:hover {
  background-color: #b71c1c;
}

.btn-box a span.icon-up-right-arrow::before {
  content: '\2197'; /* Unicode for up-right arrow */
  font-size: 20px;
}

/* Pagination Styles */
.styled-pagination {
  text-align: center;
  margin-top: 30px;
}

.styled-pagination li {
  display: inline-block;
  margin-right: 10px;
}

.styled-pagination li a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #2d438e;
  color: #ffffff;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.styled-pagination li a:hover,
.styled-pagination li.active a {
  background-color: #d32f2f;
}

.styled-pagination .arrow a {
  background-color: transparent;
  color: #2d438e;
  font-size: 24px;
}

.styled-pagination .arrow.prev a::before {
  content: '\2190'; /* Unicode for left arrow */
}

.styled-pagination .arrow.next a::before {
  content: '\2192'; /* Unicode for right arrow */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .breadcrumb-style1 {
    padding: 50px 0;
  }

  .single-blog-style1__top h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .btn-box a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .styled-pagination li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}
