:root {
  --thm-base: #0098fe;
  --thm-primary: #0D3D9F;
  --thm-red: #d31714;
}

.footer-style1 {
  background-color: #222;
  color: #BBB9BA;
}

.footer-main {
  padding: 80px 0 60px;
}

.single-footer-widget {
  margin-bottom: 40px;
}

.footer-logo-style1 {
  margin-bottom: 25px;
}

.footer-logo-style1 img {
  max-width: 150px;
  height: auto;
}

.our-company-info .text {
  margin-bottom: 25px;
}

.phone-number p {
  color: #ffffff;
  margin-bottom: 5px;
}

.phone-number h2 a {
  color: var(--thm-base);
  font-size: 22px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.phone-number h2 a:hover {
  color: var(--thm-red);
}

.phone-number p a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.phone-number p a:hover {
  color: var(--thm-base);
}

.single-footer-widget .title h3 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 25px;
}

.footer-widget-links ul,
.footer-widget-contact-info ul {
  list-style: none;
  padding: 0;
}

.footer-widget-links ul li,
.footer-widget-contact-info ul li {
  margin-bottom: 10px;
}

.footer-widget-links ul li a,
.footer-widget-contact-info ul li a {
  color: #BBB9BA;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-widget-links ul li a:hover,
.footer-widget-contact-info ul li a:hover {
  color: var(--thm-base);
}

.footer-social-link ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.footer-social-link ul li {
  margin-right: 15px;
}

.footer-social-link ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--thm-primary);
  color: #ffffff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.footer-social-link ul li a:hover {
  background-color: var(--thm-red);
}

.footer-bottom {
  border-top: 1px solid #4e4e4e;
  padding: 25px 0;
}

.copyright-text {
  text-align: center;
}

.copyright-text p {
  margin: 0;
  color: #BBB9BA;
}

.whatsapp-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp {
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
}

.whatsapp:hover {
  transform: scale(1.1);
}

@media (max-width: 991px) {
  .footer-main {
    padding: 60px 0 40px;
  }
}

@media (max-width: 767px) {
  .single-footer-widget {
    margin-bottom: 30px;
  }

  .single-footer-widget .title h3 {
    font-size: 18px;
  }

  .phone-number h2 a {
    font-size: 18px;
  }
}